<template>
  <div class="header">
    <div class="big-header" :class="{'write-bg': scrollTop>50}">
      <div class="container">
        <img class="header-logo" src="../assets/common/logo.png" alt="">
        <div class="header-menu">
          <router-link to="/">
            <span>首页</span>
          </router-link>
          <router-link to="/brand">
            <span :class="{'activited': meta.parent && meta.parent == 'brand'}">品牌介绍</span>
          </router-link>
          <router-link to="/newsList">
            <span :class="{'activited': meta.parent && meta.parent == 'news'}">新闻资讯</span>
          </router-link>
          <router-link to="/cooperate">
            <span>招商合作</span>
          </router-link>
          <router-link to="/help">
            <span>帮助中心</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="small-header">
      <div class="container">
        <img class="logo" src="../assets/common/logo.png" alt="">
        <div class="small-menu">
          <div class="menu-icon" @click="hideMenu = !hideMenu">
            <img src="../assets/home/menu.png" alt="">
          </div>

          <div class="small-munu-bg" @touchmove.stop.prevent="clear" @click="hideMenu = !hideMenu" v-if="!hideMenu"></div>
           <div class="small-menu-list"  v-if="!hideMenu" @click="hideMenu = !hideMenu">
              <router-link to="/">首页</router-link>
              <router-link to="/brand">品牌介绍</router-link>
              <router-link to="/newsList">新闻资讯</router-link>
              <router-link to="/cooperate">招商合作</router-link>
              <router-link to="/help">帮助中心</router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      scrollTop: '',
      hideMenu: true,
      meta: '',
    }
  },
  watch: {
    $route(val){
      this.meta = val.meta;
    }
  },
  mounted(){
     window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    clear(e) {
				// TODO nvue 取消冒泡
				e.stopPropagation()
		},
  }
}
</script>
<style lang="scss">
.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
  .big-header {
    position: fixed;
    left:0;
    top: 0;
    width: 100%;
    z-index: 99;
    &.write-bg {
      background-color: #fff;
      box-shadow:0px 2px 40px 0px rgba(0,0,0,0.2);
    }
    .container {
      height: 88px;
      line-height: 88px;
      display: flex;
      align-items: center;
      .header-logo {
        width: 152px;
        height: auto;
        margin-right: 127px;
      }
      .header-menu {
        flex: 1;
        a {
          color: rgba(51,51,51,.6);
          text-decoration: none;
          margin-right: 40px;
        }
        .router-link-exact-active {
          color: rgba(51,51,51,1);
        }
        .activited {
          color: #333;
        }
      }
    }
  }



  .small-header {
    width: 100%;
    display: none;
    .logo {
      width: 102px;
      // height: 26px;
      margin-top: 4vw;
    }
    .small-menu {
      position: fixed;
      top: 3vw;
      right: 5vw;
      box-shadow:0px 2px 20px 0px rgba(0,0,0,0.3);
      .menu-icon {
       width: 48px;
       height: 48px;
       background-color: #fff; 
       border-radius: 48px;
       line-height: 48px;
       text-align: center;
        img {
          width: 30px;
          vertical-align: middle;
        }
      }
    }
  }
}
@media (max-width: 1023px){
  .header {
    &.write-bg {
      background-color: transparent;
      box-shadow: none;
    }
    .big-header {
      display: none;
    }
    .small-header {
      display: flex;
      .small-menu {
        border-radius: 100%;
        .menu-icon {
          width: 50px;
          height: 50px;
          line-height: 50px;
          img {
            width: 24px;
            height: auto;
          }
        }
      }
      .small-munu-bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.1);
        z-index: 9;
      }
      .small-menu-list {
        position: absolute;
        width: 120px;
        padding: 10px 0;
        background-color: #fff;
        left: -140px;
        top: -10px;
        display: flex;
        flex-direction: column;
        line-height: 35px;
        text-align: center;
        box-shadow:0px 2px 40px 0px rgba(0,0,0,0.2);
        z-index: 10;
        a {
          text-decoration: none;
          color: #333;
          font-size: 14px;
        }
      }
      .small-menu-list::after {
        content: ' ';
        width: 0;
        height: 0;
        border: 10px solid;
        border-color: transparent transparent transparent #fff ;
        position: absolute;
        top: 10px;
        right: -20px;
      }
    }
  }
}
</style>