import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'swiper/swiper-bundle.css'
import vueSwiper from 'vue-awesome-swiper'; // 导入vue-awesome-swiper库

Vue.config.productionTip = false
Vue.use(vueSwiper)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
