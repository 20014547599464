<template>
  <div class="fixed-right hidden-xs">
    <div class="fixed-item">
      <a href='javascript:;' onclick='easemobim.bind({configId: "0f811502-aae4-4ab0-8900-dc8b09bf5b7e"})'>
        <img src="../assets/fixed/service.png" alt="">
      </a>
    </div>
    <div class="fixed-item">
      <img src="../assets/fixed/app.png" alt="">
      <div class="fixed-item-hover">
        <img src="../assets/footer/app.png" alt="">
        <div>扫一扫<br/>下载倾伊美APP</div>
      </div>
    </div>
    <div class="fixed-item">
      <img src="../assets/fixed/gongzhonghao.png" alt="">
      <div class="fixed-item-hover">
        <img src="../assets/footer/wechat.png" alt="">
        <div>扫一扫<br/>关注倾伊美公众号</div>
      </div>
    </div>
    <div class="fixed-item">
      <img src="../assets/fixed/xiaochengxu.png" alt="">
      <div class="fixed-item-hover">
        <img src="../assets/footer/xcx.png" alt="">
        <div>扫一扫<br/>打开倾伊美小程序</div>
      </div>
    </div>
    <div class="fixed-item mr-10" @click="backTop">
      <img src="../assets/fixed/back_top.png" alt="">
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  methods: {
    backTop(){
      var doc= document.documentElement;
      $(doc).animate({
        scrollTop:0
      },500)
    },
  }
}
</script> 
<style lang="scss">
.fixed-right {
  width: 50px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  .fixed-item {
    width: 50px;
    height: 50px;
    background:rgba(0,0,0,.3);
    position: relative;
  }
  .mr-10 {
    margin-top: 20px;
  }
   .fixed-item:hover {
       .fixed-item-hover {
         display: flex;
       }
   }

  .fixed-item-hover {
    width: 120px;
    // height: 110px;
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: -140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    text-align: center;
    display: none;
    padding: 10px;
    img {
      width: 80px;
      margin-bottom: 10px;
    }
  }
  .fixed-item-hover::after {
    content: ' ';
    width: 0;
    height: 0;
    border: 10px solid;
    border-color: transparent transparent transparent rgba(0,0,0,.3);
    position: absolute;
    top: 10px;
    right: -20px;
  }
}
</style>